<template>
  <div ref="betSlip" class="shadow-2xl float-right z-10 bg-gray-800"
       :class="[isOpen ? 'w-80' : 'w-40', {'is-fixed-to-top': isFixedToTop}]">
    <div
        class="mb-1 py-1 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent"
        :class="{'rounded-b-lg' : !isOpen}">
      <div class="flex items-center gap-3">
        <svg class="w-5 h-5 fill-current transform -rotate-45 text-white" viewBox="0 0 20 20">
          <path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z"/>
        </svg>
        <a href="#" class="text-sm uppercase font-bold text-white cursor-pointer hover:underline">Schedina</a>
      </div>

      <button class="text-blue-900" @click="isOpen = !isOpen">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
             v-if="isOpen">
          <path class="text-white" stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M19 9l-7 7-7-7"/>
        </svg>
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
             v-else>
          <path class="text-white" stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M9 5l7 7-7 7"/>
        </svg>
      </button>
    </div>
    <div class="w-full overflow-hidden" :class="[isOpen ? 'h-auto' : 'h-0']">
      <div class="mt-1 w-full bg-white py-3 px-4">
        <h2 class="uppercase font-semibold text-lg mb-2 text-gray-900">Singola</h2>

        <div v-for="i in 1" :key="i">
          <div class="inline-flex items-center gap-2">
            <button>
              <svg class="w-6 h-6 stroke-current" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path class="text-gray-900" stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
            </button>
            <div class="inline-flex items-center gap-1 divide-x divide-gray-900">
              <span class="text-gray-900 text-xs px-1 leading-tight">Serie A</span>
              <span class="text-gray-900 text-xs px-1 leading-tight">30/12/2020</span>
              <span class="text-gray-900 text-xs px-1 leading-tight">13:55</span>
            </div>
          </div>
          <p class="text-primary font-semibold text-sm leading-tight">A.S. Roma - Juventus</p>
          <div class="w-full inline-flex items-end justify-between">
            <div class="inline-flex items-end gap-1">
              <button>
                <svg class="stroke-current w-8 h-8" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path class="text-gray-900" stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                </svg>
              </button>
              <p class="text-xs text-gray-900">T/T1</p>
            </div>
            <p class="text-xs text-gray-900">8.25</p>
          </div>
        </div>
      </div>
      <div class="mt-1 w-full bg-white py-1 px-4 flex items-center justify-between">
        <p class="text-sm text-gray-900 leading-tight">Puntata</p>

        <div class="p-1 rounded-md bg-gray-400 flex items-center justify-between w-32">
          <button
              class="bg-white rounded-md p-1 text-gray-900 text-sm w-5 h-5 leading-none flex items-center justify-center">
            -
          </button>
          <p class="flex-1 text-center text-gray-900 text-sm">3,00€</p>
          <button
              class="bg-white rounded-md p-1 text-gray-900 text-sm w-5 h-5 leading-none flex items-center justify-center">
            +
          </button>
        </div>
      </div>
      <div class="mt-1 w-full bg-secondary py-3 px-4 flex flex-col gap-2">
        <div class="w-full flex items-center justify-between">
          <p class="text-sm font-semibold text-gray-900">Quota totale</p>
          <p class="text-sm text-gray-900">8.25</p>
        </div>
        <div class="w-full flex items-center justify-between">
          <p class="text-sm font-semibold text-gray-900">Importo Scommessa</p>
          <p class="text-sm text-gray-900">3,00€</p>
        </div>
        <div class="w-full flex items-center justify-between">
          <p class="text-md font-semibold text-white uppercase">Possible Vincita</p>
          <p class="text-md font-semibold text-white">24,75€</p>
        </div>
      </div>
      <div class="w-full bg-gray-800 pt-1 pb-2 rounded-b-lg">
        <div class="px-2 flex items-center justify-between gap-2">
          <button class="whitespace-nowrap py-2 px-5 uppercase text-white text-md font-semibold bg-green-500">Prenota
          </button>
          <button class="whitespace-nowrap py-2 px-5 uppercase text-white text-md font-semibold bg-green-700">Scommetti
            Ora
          </button>
        </div>

        <div class="w-full bg-white py-3 px-4 mt-1">
          <div class="inline-flex items-center gap-1">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="text-primary" stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
              <path class="text-primary" stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
            </svg>

            <span class="font-semibold text-xs text-primary ">Acceta automaticamente cambio quote</span>
          </div>

          <div class="w-full inline-flex items-center gap-4">
            <div class="inline-flex items-center gap-1">
              <input type="checkbox"/>
              <span class="text-xs text-gray-900">Mai</span>
            </div>
            <div class="inline-flex items-center gap-1">
              <input type="checkbox"/>
              <span class="text-xs text-gray-900">Solo quote più alte</span>
            </div>
            <div class="inline-flex items-center gap-1">
              <input type="checkbox"/>
              <span class="text-xs text-gray-900">Sempre</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {throttle} from 'lodash';

export default {
  name: "BetSlipWidget",

  data() {
    return {
      isOpen: true,
      isFixedToTop: false
    }
  },

  mounted() {
    this.$nextTick(() => {
      const scrollableContent = document.getElementById('scrollable-content');
      let el = this.$el;
      let originalPosition = el.offsetTop;

      this.isFixedToTop = scrollableContent.scrollTop >= originalPosition;

      scrollableContent.addEventListener('scroll', throttle( () => {
        this.isFixedToTop = scrollableContent.scrollTop >= originalPosition;
      }, 100));

    });
  },
}
</script>

<style scoped lang="postcss">
.is-fixed-to-top {
  position: fixed;
  top: 7%;
  right: 1%;
}
</style>
