<template>
  <div class="">
    <FullWidthBanner/>

    <SectionTitle title="Scommesse Sportiva">
      <div class="pb-4 pt-16 px-6 shadow-xl" slot="filters">
        <div class="inline-flex items-center divide-x divide-white">
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Calcio</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Tennis</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Pallavolo</a>
          <a href="#" class="uppercase text-xs font-semibold px-12 hover:text-primary">Equitazione</a>
        </div>
      </div>
    </SectionTitle>

    <BetSlipWidget/>

    <section class="w-full">
      <div class="relative px-24 mt-10 flex items-center">
        <span class="p-1 rounded-lg bg-primary text-gray-900 text-xs font-bold">{{ (new Date()).getDate() < 10 ? "0" + (new Date()).getDate() : (new Date()).getDate() }}</span>
        <h1 class="uppercase text-primary text-shadow text-2xl font-bold ml-2 mr-1">LIVE</h1>
        <span class="object-none object-right-top w-3 h-3 bg-green-500 animate-pulse rounded-full"></span>
      </div>

      <div class="px-16 flex items-center justify-end my-3">
        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6 stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M15 19l-7-7 7-7"/>
          </svg>
        </button>

        <button class="text-white hover:text-primary group">
          <svg class="w-6 h-6z stroke-current" fill="none" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M9 5l7 7-7 7"/>
          </svg>
        </button>
      </div>

      <div class="px-16 flex items-center justify-between gap-6">
        <div class="relative flex-1 ">
          <div
              class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <div class="flex items-center gap-3">
              <custom-image original="/img/icons/FOOTBALL.png" class-list="w-5"/>
              <a href="#" class="font-semibold text-white cursor-pointer hover:underline">Italia > Serie A</a>
            </div>

            <p class="text-white">48' | 2º T</p>
          </div>
          <div class="mt-1 w-full bg-white">
            <div class="flex items-center justify-around px-6 py-10">
              <p class="text-gray-900 font-semibold text-base text-left">A.S. Roma</p>
              <p class="text-primary font-bold text-3xl">2 : 2</p>
              <p class="text-gray-900 font-semibold text-base text-right">Juventos</p>
            </div>
            <div class="border-t-2 border-gray-400 flex items-stretch justify-between">
              <div class="px-8 py-2 bg-gray-400 text-gray-200 cursor-not-allowed">
                <custom-image original="/img/icons/LOCK_IT.png" class-list="w-4"/>
              </div>
              <div class="px-8 py-2 bg-gray-400 text-gray-200 cursor-not-allowed">
                <custom-image original="/img/icons/LOCK_IT.png" class-list="w-4"/>
              </div>
              <div class="px-8 py-2 bg-gray-400 text-gray-200 cursor-not-allowed">
                <custom-image original="/img/icons/LOCK_IT.png" class-list="w-4"/>
              </div>
            </div>
          </div>
        </div>
        <div class="relative flex-1 ">
          <div
              class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <div class="flex items-center gap-3">
              <img src="/img/icons/BASEBALL.png" class="w-5" alt=""/>
              <a href="#" class="font-semibold text-white cursor-pointer hover:underline">Egitto > ITF Egitto</a>
            </div>

            <p class="text-white">Dec 15 | Gioco 9</p>
          </div>
          <div class="mt-1 w-full bg-white">
            <div class="flex items-center justify-around px-6 py-10">
              <p class="text-gray-900 font-semibold text-base text-left">Kadhe Arjun</p>
              <p class="text-primary font-bold text-3xl">30 : 30</p>
              <p class="text-gray-900 font-semibold text-base text-right">Ornago Fabrizio</p>
            </div>
            <div class="border-t-2 border-gray-400 flex items-stretch justify-between">
              <div class="px-4 py-2 bg-gray-400 text-sm font-semibold cursor-pointer flex items-center gap-6">
                <span class="text-gray-900">1</span>
                <div class="flex items-center gap-1">
                  <span class="text-gray-900">6.00</span>
                  <svg class="w-6 h-6 fill-current" viewBox="0 0 20 20">
                    <path class="text-red-900"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"/>
                  </svg>
                </div>
              </div>
              <div class="px-4 py-2 bg-gray-400 text-sm font-semibold cursor-pointer flex items-center gap-6">
                <span class="text-gray-900">2</span>
                <div class="flex items-center gap-1">
                  <svg class="w-6 h-6 fill-current" viewBox="0 0 20 20">
                    <path class="text-green-700"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"/>
                  </svg>
                  <span class="text-gray-900">1.07</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="relative flex-1 ">
          <div
              class="mb-1 py-2 px-4 w-full flex items-center justify-between rounded-t-lg bg-gradient-to-r from-primary via-blue-800 to-transparent">
            <div class="flex items-center gap-3">
              <img src="/img/icons/BASEBALL.png" class="w-5" alt=""/>
              <a href="#" class="font-semibold text-white cursor-pointer hover:underline">Egitto > ITF Egitto</a>
            </div>

            <p class="text-white">Dec 15 | Gioco 9</p>
          </div>
          <div class="mt-1 w-full bg-white">
            <div class="flex items-center justify-around px-6 py-10">
              <p class="text-gray-900 font-semibold text-base text-left">Kadhe Arjun</p>
              <p class="text-primary font-bold text-3xl">30 : 30</p>
              <p class="text-gray-900 font-semibold text-base text-right">Ornago Fabrizio</p>
            </div>
            <div class="border-t-2 border-gray-400 flex items-stretch justify-between">
              <div class="px-4 py-2 bg-gray-400 text-sm font-semibold cursor-pointer flex items-center gap-6">
                <span class="text-gray-900">1</span>
                <div class="flex items-center gap-1">
                  <span class="text-gray-900">6.00</span>
                  <svg class="w-6 h-6 fill-current" viewBox="0 0 20 20">
                    <path class="text-red-900"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"/>
                  </svg>
                </div>
              </div>
              <div class="px-4 py-2 bg-gray-400 text-sm font-semibold cursor-pointer flex items-center gap-6">
                <span class="text-gray-900">2</span>
                <div class="flex items-center gap-1">
                  <svg class="w-6 h-6 fill-current" viewBox="0 0 20 20">
                    <path class="text-green-700"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"/>
                  </svg>
                  <span class="text-gray-900">1.07</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="w-full mt-24">
      <div class="px-16 mt-10">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">In Evidenza</h1>
      </div>

      <div class="px-16 py-8">
        <div class="flex items-center justify-between gap-3">
          <button
              class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-gradient-to-l from-primary via-blue-800 to-transparent">
            <custom-image original="/img/icons/FOOTBALL.png" class-list="w-5"/>
            <span class="uppercase text-md">Champions League</span>
          </button>
          <button class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-white">
            <custom-image original="/img/icons/ITALY_FLAG.png" class-list="w-5"/>
            <span class="uppercase text-md text-gray-900">Serie A</span>
          </button>
          <button class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-white">
            <custom-image original="/img/icons/ENGLAND_FLAG.png" class-list="w-5"/>
            <span class="uppercase text-md text-gray-900">Premier League</span>
          </button>
        </div>

        <table class="table-auto w-full mt-1">
          <thead>
          <tr class="bg-gradient-to-l from-primary to-white">
            <th colspan="2" class="whitespace-nowrap py-1 px-4">
              <div class="flex items-center gap-3">
                <custom-image original="/img/icons/FOOTBALL.png" class-list="w-5"/>
                <span class="uppercase text-sm font-semibold text-gray-900">UEFA > Champions League</span>
              </div>
            </th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">1</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">X</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">2</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">-</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">U 2.5</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">O 2.5</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">GG</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">NG</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">-</th>
          </tr>
          </thead>
          <tbody>
          <tr class="bg-white border-t-2 border-gray-700" v-for="i in 11" :key="i">
            <td class="whitespace-nowrap py-1 text-center text-xs">
              <span class="block w-full px-4 border-r-2 text-gray-900">8/12<br/>17:55</span>
            </td>
            <td class="whitespace-nowrap py-1 text-left text-sm font-semibold">
              <a href="#" class="block w-full px-4 border-r-2 text-gray-900 cursor-pointer hover:underline">
                Lazio - Club Brugge
              </a>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.90</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">3.70</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">4.00</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-primary">2.5</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.35</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.75</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.68</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">2.05</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 text-primary">+386</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section class="w-full mt-24">
      <div class="px-16 mt-10">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">Proximi Eventi</h1>
      </div>

      <div class="px-16 py-8">
        <div class="flex items-center justify-between gap-3">
          <button
              class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-gradient-to-l from-primary via-blue-800 to-transparent">
            <custom-image original="/img/icons/FOOTBALL.png" class-list="w-5"/>
            <span class="uppercase text-md">Calcio</span>
          </button>
          <button class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-white">
            <!--            <img src="/img/icons/ITALY_FLAG.png" class="w-5" alt=""/>-->
            <span class="uppercase text-md text-gray-900">Tennis</span>
          </button>
          <button class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-white">
            <!--            <img src="/img/icons/ENGLAND_FLAG.png" class="w-5" alt=""/>-->
            <span class="uppercase text-md text-gray-900">Basket</span>
          </button>
          <button class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-white">
            <!--            <img src="/img/icons/ENGLAND_FLAG.png" class="w-5" alt=""/>-->
            <span class="uppercase text-md text-gray-900">Volley</span>
          </button>
          <button class="flex-1 py-2 px-6 flex items-center justify-center gap-3 bg-white">
            <!--            <img src="/img/icons/ENGLAND_FLAG.png" class="w-5" alt=""/>-->
            <span class="uppercase text-md text-gray-900">Rugby</span>
          </button>
        </div>

        <table class="table-auto w-full mt-1">
          <thead>
          <tr class="bg-gradient-to-l from-primary to-white">
            <th colspan="2" class="whitespace-nowrap py-1 px-4">
              <div class="flex items-center gap-3">
                <custom-image original="/img/icons/FOOTBALL.png" class-list="w-5"/>
                <span class="uppercase text-sm font-semibold text-gray-900">UEFA > Champions League</span>
              </div>
            </th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">1</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">X</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">2</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">-</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">U 2.5</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">O 2.5</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">GG</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">NG</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">-</th>
          </tr>
          </thead>
          <tbody>
          <tr class="bg-white border-t-2 border-gray-700" v-for="i in 6" :key="i">
            <td class="whitespace-nowrap py-1 text-center text-xs">
              <span class="block w-full px-4 border-r-2 text-gray-900">8/12<br/>17:55</span>
            </td>
            <td class="whitespace-nowrap py-1 text-left text-sm font-semibold">
              <a href="#" class="block w-full px-4 border-r-2 text-gray-900 cursor-pointer hover:underline">
                Lazio - Club Brugge
              </a>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.90</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">3.70</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">4.00</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-primary">2.5</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.35</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.75</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.68</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">2.05</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 text-primary">+386</span>
            </td>
          </tr>
          </tbody>
          <thead>
          <tr class="bg-gradient-to-l from-primary to-white border-t-4 border-gray-700">
            <th colspan="2" class="whitespace-nowrap py-1 px-4">
              <div class="flex items-center gap-3">
                <img src="/img/icons/FOOTBALL.png" class="w-5" alt=""/>
                <span class="uppercase text-sm font-semibold text-gray-900">Italia > Serie A</span>
              </div>
            </th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">1</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">X</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">2</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">-</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">U 2.5</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">O 2.5</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">GG</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">NG</th>
            <th class="whitespace-nowrap py-1 px-4 text-gray-900 uppercase text-sm font-semibold">-</th>
          </tr>
          </thead>
          <tbody>
          <tr class="bg-white border-t-2 border-gray-700" v-for="i in 4" :key="i">
            <td class="whitespace-nowrap py-1 text-center text-xs">
              <span class="block w-full px-4 border-r-2 text-gray-900">8/12<br/>17:55</span>
            </td>
            <td class="whitespace-nowrap py-1 text-left text-sm font-semibold">
              <a href="#" class="block w-full px-4 border-r-2 text-gray-900 cursor-pointer hover:underline">
                Lazio - Club Brugge
              </a>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.90</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">3.70</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">4.00</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-primary">2.5</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.35</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.75</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">1.68</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 border-r-2 text-gray-900">2.05</span>
            </td>
            <td class="whitespace-nowrap py-1 text-center text-sm">
              <span class="block w-full px-4 text-primary">+386</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import FullWidthBanner from "@/components/FullWidthBanner";
import SectionTitle from "@/components/SectionTitle";
import BetSlipWidget from "@/components/BetSlipWidget";

export default {
  components: {
    FullWidthBanner,
    SectionTitle,
    BetSlipWidget
  }
}
</script>
